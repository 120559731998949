<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        head-title="基本信息"
        @head-cancel="headCancel"
      ></head-layout>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="视频" name="videoFrequency">
          <videoFrequency ref="videoTable" type="view"></videoFrequency>
        </el-tab-pane>
        <el-tab-pane label="课件" name="courseware">
          <courseware ref="courseware" type="view"></courseware>
        </el-tab-pane>
        <el-tab-pane label="试题" name="testQuestions">
          <testQuestions ref="testQuestions" :type="type" :tainDetail="detail"></testQuestions>
        </el-tab-pane>
      </el-tabs>
    </el-main>

    <el-dialog
      title="我的培训"
      :modal-append-to-body="false"
      :visible.sync="videoShow"
      :before-close="videoHandleClose"
      :fullscreen="fullscreen"
      class="qmDialog"
      top="5vh"
      width="80%"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <VideoDialog v-if="videoShow" ref="VideoDialog"></VideoDialog>
    </el-dialog>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {getDetail, getIsCompulsoryDicData, myTrainingDetail} from "@/api/training/trainingEducation";
import CommonTree from "@/views/components/com_tree/index.vue";
import website from "@/config/website";
import {Base64} from "js-base64";
import {downloadFileBlob} from "@/util/util";
import VideoDialog from "./videoDialog.vue";
import {trainingEducationSetHeadDetail} from "@/api/training/reEducationSetting";
import videoFrequency from "@/views/business/training/trainingEducation/components/videoFrequency";
import courseware from "@/views/business/training/trainingEducation/components/courseware";
import testQuestions from "@/views/business/training/trainingEducation/components/testQuestions";


export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    VideoDialog,
    videoFrequency,
    courseware,
    testQuestions
  },
  data() {
    return {
      activeName: 'videoFrequency',
      name: "videoFrequency",
      fileUrl: "",
      videoShow: false,
      fullscreen: false,
      showFileDialog: false,
      dicData: [],
      dataForm: {},
      type: ""
    }
  },
  computed: {
    formColumn() {
      return [
        {
          label: "课程名称",
          prop: "courseName",
          labelWidth: 120,
          readonly: true,
          span: 8,
          rules: [{
            required: true,
            message: "请输入课程名称",
            trigger: "blur"
          }]
        },
        {
          label: "教育时间",
          prop: "trainingTimeRange",
          readonly: true,
          labelWidth: 120,
          span: 8,
          rules: [{
            required: true,
            message: "请选择教育时间",
            trigger: "blur"
          }]
        },
        {
          label: "培训类型",
          prop: "trainingType",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "合计课时",
          prop: "totalHours",
          labelWidth: 120,
          readonly: true,
          span: 8,
        },
        {
          label: "达标课时",
          prop: "standardsHours",
          readonly: true,
          labelWidth: 120,
          span: 8,
          rules: [{
            required: true,
            trigger: "blur"
          }]
        },
        {
          label: "已完成必修课时",
          prop: "accomplishAccomplish",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "课程简介",
          prop: "remark",
          readonly: true,
          labelWidth: 120,
          placeholder: "请输入课程简介",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 500,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入课程简介",
            trigger: "blur"
          }]
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      return buttonBtn;
    },
  },
  methods: {
    handleClick(tab, event) {
      this.name = tab._props.name
      if (tab._props.name == "videoFrequency") {
        this.$refs.videoTable.detail(this.$route.query.id)
      } else if (tab._props.name == "courseware") {
        this.$refs.courseware.detail(this.$route.query.id)
      } else {
        this.$refs.testQuestions.detail(this.$route.query.id)
      }
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    videoHandleClose() {
      this.$refs.VideoDialog.clearTime();
      this.videoShow = false;
      this.detail(this.dataForm.id)
    },
    formatIsCompulsory(row, column) {
      const value = row[column.property];
      const dict = this.dicData.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    detail(id) {
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
      })
    },
  },
  mounted() {
    getIsCompulsoryDicData().then((res) => {
      this.dicData = res.data.data;
    })
    if (this.name == "videoFrequency") {
      this.$refs.videoTable.detail(this.$route.query.id)
    }
    var type = this.$route.query.type;
    if (type == "additionalRecording") {
      this.type = "additionalRecording"
    }else if(type == "recordScores"){
      this.type = "recordScores"
    }else {
      this.type = type
    }
    this.dataForm.id = this.$route.query.id;
    this.detail(this.dataForm.id);
  }
}
</script>

<style scoped>
.margin-table {
  margin-left: 30px;
  margin-right: 30px;
}


.blue-underline {
  text-decoration: underline;
  color: #409EFF;
  cursor: pointer; /* 添加鼠标悬停时的指针样式 */
}
</style>
