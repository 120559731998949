var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eventManagement-form-box" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "事故基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "150px",
                    disabled: _vm.formType == "view",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故编号", prop: "accCode" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.dataForm.isAutomatic ||
                                      _vm.pageDisabled ||
                                      _vm.dataForm.id,
                                    placeholder: "请填写事故编号",
                                  },
                                  model: {
                                    value: _vm.dataForm.accCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "accCode", $$v)
                                    },
                                    expression: "dataForm.accCode",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _vm._v(
                                        "\n                    自动生成\n                    "
                                      ),
                                      _c("el-switch", {
                                        attrs: {
                                          disabled:
                                            _vm.pageDisabled || _vm.dataForm.id,
                                          "active-color": "#13ce66",
                                        },
                                        on: { change: _vm.handleGetCode },
                                        model: {
                                          value: _vm.dataForm.isAutomatic,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "isAutomatic",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.isAutomatic",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故标题", prop: "accTitle" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  title: _vm.dataForm.accTitle,
                                  maxlength: "100",
                                  placeholder: "请填写事故标题",
                                },
                                model: {
                                  value: _vm.dataForm.accTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "accTitle", $$v)
                                  },
                                  expression: "dataForm.accTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发生地点", prop: "address" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  title: _vm.dataForm.address,
                                  maxlength: "60",
                                  placeholder: "请填写发生地点",
                                },
                                model: {
                                  value: _vm.dataForm.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "address", $$v)
                                  },
                                  expression: "dataForm.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "报告人", prop: "userName" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择报告人" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleUserModeOpen()
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "userName", $$v)
                                  },
                                  expression: "dataForm.userName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发生时间", prop: "accTime" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择报告时间",
                                },
                                model: {
                                  value: _vm.dataForm.accTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "accTime", $$v)
                                  },
                                  expression: "dataForm.accTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "简要经过", prop: "brifDesc" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder:
                                    "请输入事故发生的简要经过和发生的原因",
                                },
                                model: {
                                  value: _vm.dataForm.brifDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "brifDesc", $$v)
                                  },
                                  expression: "dataForm.brifDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "伤亡损失情况", prop: "loss" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder:
                                    "请输入事故人员和财产损失的基本情况",
                                },
                                model: {
                                  value: _vm.dataForm.loss,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "loss", $$v)
                                  },
                                  expression: "dataForm.loss",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "事故处理措施",
                                prop: "treatment",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder:
                                    "请输入事故发生后采取的处理措施及事故控制情况",
                                },
                                model: {
                                  value: _vm.dataForm.treatment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "treatment", $$v)
                                  },
                                  expression: "dataForm.treatment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "现场情况", prop: "treeData" } },
                            [
                              _vm.formType != "view"
                                ? _c(
                                    "el-upload",
                                    {
                                      ref: "uploadRef1",
                                      staticClass: "upload-demo",
                                      staticStyle: {
                                        "margin-bottom": "12px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                      attrs: {
                                        accept: ".jpg,.mp4,.png",
                                        action:
                                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                        "show-file-list": false,
                                        "before-upload": _vm.checkFileType,
                                        "on-success": _vm.handleAvatarSuccess,
                                        headers: _vm.headers,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-upload",
                                          },
                                        },
                                        [_vm._v("点击上传\n                  ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "img-content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getNodeClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnailLink,
                                          alt: "",
                                        },
                                      }),
                                      _vm.formType !== "view"
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-close delete-icon",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleRemove(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.dataForm.id && _vm.dataForm.accStatus !== "PREPARE"
        ? _c("head-layout", { attrs: { "head-title": "事故调查结果" } })
        : _vm._e(),
      _vm.dataForm.id && _vm.dataForm.accStatus !== "PREPARE"
        ? _c("div", { staticClass: "formContentBox" }, [
            _c("div", { staticClass: "formMain" }, [
              _c(
                "div",
                { staticStyle: { padding: "0 12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm1",
                      attrs: {
                        model: _vm.dataForm,
                        "label-width": "150px",
                        disabled: _vm.formType == "view",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "事故类别", prop: "accType" },
                                },
                                [
                                  _c("avue-select", {
                                    attrs: {
                                      props: {
                                        label: "dictValue",
                                        value: "dictKey",
                                      },
                                      placeholder: "请选择事故类别",
                                      type: "tree",
                                      dic: _vm.ACC_TYPE,
                                    },
                                    model: {
                                      value: _vm.dataForm.accType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "accType", $$v)
                                      },
                                      expression: "dataForm.accType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事故级别",
                                    prop: "accLevel",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-container flex-align-c",
                                    },
                                    [
                                      _c("avue-select", {
                                        attrs: {
                                          props: {
                                            label: "dictValue",
                                            value: "dictKey",
                                          },
                                          placeholder: "请选择事故级别",
                                          type: "tree",
                                          dic: _vm.ACC_LEVEL,
                                        },
                                        model: {
                                          value: _vm.dataForm.accLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accLevel",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.accLevel",
                                        },
                                      }),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            placement: "bottom",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "content",
                                                fn: function () {
                                                  return [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.accLevelText
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1680245037
                                          ),
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "24px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "直接经济损失",
                                    prop: "lossFa",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        min: "0",
                                        type: "number",
                                        title: _vm.dataForm.lossFa,
                                        placeholder:
                                          "请输入直接经济损失评估结果",
                                      },
                                      model: {
                                        value: _vm.dataForm.lossFa,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "lossFa", $$v)
                                        },
                                        expression: "dataForm.lossFa",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("万元"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "间接经济损失",
                                    prop: "lossIndirect",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-container flex-align-c",
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: "1",
                                          controls: false,
                                          placeholder:
                                            "请输入间接经济损失评估结果",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossIndirect,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossIndirect",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.lossIndirect",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "lossIndirect" },
                                        [_vm._v("万元")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "工时损失",
                                    prop: "lossTime",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-container flex-align-c",
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: "0",
                                          controls: false,
                                          title: _vm.dataForm.lossTime,
                                          placeholder: "请输入工时损失",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossTime",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.lossTime",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "lossIndirect" },
                                        [_vm._v("H")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "死亡人数",
                                    prop: "lossDeath",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      min: 0,
                                      "step-strictly": "",
                                      step: 1,
                                      max: 9999,
                                      type: "number",
                                      placeholder: "请填写死亡人数",
                                    },
                                    model: {
                                      value: _vm.dataForm.lossDeath,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "lossDeath",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "dataForm.lossDeath",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "重伤人数",
                                    prop: "lossSerious",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      min: 0,
                                      "step-strictly": "",
                                      step: 1,
                                      max: 9999,
                                      type: "number",
                                      placeholder: "请填写重伤人数",
                                    },
                                    model: {
                                      value: _vm.dataForm.lossSerious,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "lossSerious",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "dataForm.lossSerious",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "轻伤人数",
                                    prop: "lossMinor",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      min: 0,
                                      "step-strictly": "",
                                      step: 1,
                                      max: 9999,
                                      type: "number",
                                      placeholder: "请填写轻伤人数",
                                    },
                                    model: {
                                      value: _vm.dataForm.lossMinor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "lossMinor",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "dataForm.lossMinor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "其他损失",
                                    prop: "lossOther",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入其他损失描述信息",
                                    },
                                    model: {
                                      value: _vm.dataForm.lossOther,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "lossOther", $$v)
                                      },
                                      expression: "dataForm.lossOther",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "根本原因", prop: "reason" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入事故发生的根本原因",
                                    },
                                    model: {
                                      value: _vm.dataForm.reason,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "reason", $$v)
                                      },
                                      expression: "dataForm.reason",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事故详细经过",
                                    prop: "accDesc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder:
                                        "请输入事故发生的详细经过和原因",
                                    },
                                    model: {
                                      value: _vm.dataForm.accDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "accDesc", $$v)
                                      },
                                      expression: "dataForm.accDesc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "责任认定及处理建议",
                                    prop: "accResp",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入责任认定和处理建议",
                                    },
                                    model: {
                                      value: _vm.dataForm.accResp,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "accResp", $$v)
                                      },
                                      expression: "dataForm.accResp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "整改措施",
                                    prop: "suggustTreatment",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入建议的整改措施",
                                    },
                                    model: {
                                      value: _vm.dataForm.suggustTreatment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "suggustTreatment",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.suggustTreatment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "调查报告",
                                    prop: "treeData1",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        "on-preview": _vm.handlePreview,
                                        disabled: _vm.formType == "view",
                                        accept: ".rar,.zip,.doc,.docx,.pdf",
                                        action:
                                          "api/sinoma-resource/oss/endpoint/put-file",
                                        "on-success": _vm.handleAvatarSuccess1,
                                        "before-upload": _vm.checkFileType1,
                                        "on-remove": _vm.handleRemove1,
                                        headers: _vm.headers,
                                        multiple: "",
                                        "file-list": _vm.treeData1,
                                      },
                                    },
                                    [
                                      _vm.formType != "view"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                icon: "el-icon-upload",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "点击上传\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.formType != "view"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    支持扩展名：.rar .zip .doc .docx .pdf\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }